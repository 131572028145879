import React from 'react';
import { 
  Avatar, 
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  IconButton, 
  Flex 
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
// import { loadStripe } from '@stripe/stripe-js';

import FastAPIClient from "../../client";
import config from "../../config";

const client = new FastAPIClient(config);

const handleMakePayment = async () => {
  await client.makeStripePayment();
};

const ProfileMenu = ( {handleLogout, isTestUser, initial} ) => {
  return (
    <Flex justify="flex-end" p={0} alignItems="center">
      <Menu
      border={'none'}>
        <MenuButton
          as={IconButton}
          aria-label="Profile Options"
          icon={<Avatar size="sm"/>}
          variant="ghost"
          border={'none'}
        >
          <ChevronDownIcon />
        </MenuButton>
        <MenuList
            sx={{
            border: 'none',    // Removes the outer border of the menu
            boxShadow: 'lg'     // Optional: Adds shadow for a more subtle look
            }}
        >
          { isTestUser && initial=='SU' && (<MenuItem border={'none'} _hover={{ bg: 'gray.100' }} onClick={ handleMakePayment }>Manage Subscription</MenuItem> )}
          <MenuItem border={'none'} _hover={{ bg: 'gray.100' }} onClick={ handleLogout }>Logout</MenuItem>
          { isTestUser && initial=='SU' && (<MenuItem border={'none'} _hover={{ bg: 'gray.100' }} onClick={ handleMakePayment }>Private</MenuItem> )}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default ProfileMenu;
