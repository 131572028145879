import React , { useState } from "react";
import { useNavigate } from "react-router-dom";
// import './index.css';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import UpdatePassword from "./components/UpdatePassword";
import ErrorPage from "./pages/error-page";
import HomePage from "./components/HomePage";
// import FileDashboard from "./components/FileDashboard";
import Dashboard from './components/Dashboard';
import PrivacyPolicy from "./components/Policy/PrivacyPolicy";
// import ModeSelectPage from './pages/mode-select-page';

import { AuthProvider, useAuth } from "./contexts/AuthContext";
import FastAPIClient from "./client";
import config from './config';
const client = new FastAPIClient(config);

import { GoogleOAuthProvider } from '@react-oauth/google';
import { useEffect } from "react";

const PrivateRoute = ({ component: Component }, props) => {
  const [loading, setLoading] = useState(true);

  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setLoading(false);
      return;
    }
    navigate("/home");
  }, [user, navigate]);

  return <>{loading ? "Loading..." : <Component {...props} />} </>;
}

export default function App() {
  useEffect(() => {
    client.wakeUp()
  }, []);

  return (
    <GoogleOAuthProvider clientId={config.googleClientId}>
      <AuthProvider>
        <div className="App">
          <BrowserRouter>
            <Routes>
              {/* <Route path="/" element={<PrivateRoute component={Dashboard} />} /> */}
              <Route path="/" element={<HomePage />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/chatdashboard" element={<PrivateRoute component={Dashboard} />} />
              {/* <Route path="/filedashboard" element={<FileDashboard />} /> */}
              {/* <Route path="/mode-select" element={<ModeSelectPage />} /> */}
              <Route path="/reset-password" element={<UpdatePassword />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route exact={true} path="*" element={<ErrorPage />} />
              
            </Routes>
          </BrowserRouter>
        </div>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}
