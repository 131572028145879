// trigger reset
import config from "./config";
import jwtDecode from "jwt-decode";
import * as moment from "moment";

const axios = require("axios");

class FastAPIClient {
  constructor(overrides) {
    this.config = {
      ...config,
      ...overrides,
    };
    this.authToken = config.authToken;

    this.login = this.login.bind(this);
    this.apiClient = this.getApiClient(this.config);
  }

  /* ----- Authentication & User Operations ----- */

  /* Authenticate the user with the backend services.
   * The same JWT should be valid for both the api and cms */
  login(username, password) {
    delete this.apiClient.defaults.headers["Authorization"];

    // HACK: This is a hack for scenario where there is no login form
    const form_data = new FormData();
    const grant_type = "password";
    const item = { grant_type, username, password };
    for (const key in item) {
      form_data.append(key, item[key]);
    }

    
    return this.apiClient.post("/auth/login", form_data)
      .then((resp) => {
        return JSON.stringify(resp.data);
        // localStorage.setItem("authToken", JSON.stringify(resp.data));
        // return this.fetchUser();
      })
      .catch(() => {
        // console.log(resp)
        // console.error(err);
        return false;
      }
    );
  }

  login_from_reset(token) {
    delete this.apiClient.defaults.headers["Authorization"];
    localStorage.setItem("authToken", JSON.stringify({ access_token: token }));
  }

  fetchUser() {
    return this.apiClient.get("/auth/me").then(({ data }) => {
      // localStorage.setItem("user", JSON.stringify(data));
      return data;
    });
  }

  resetPassword(email) {
    return this.apiClient
      .post(
        `/auth/request-reset-password`,
        {},
        { params: { email_address: email } }
      )
      .then(({ data }) => {
        return data;
      });
  }

  updatePassword(email, password, token) {
    const updateData = {
      grant_type: "password",
      username: email,
      password: password,
    };

    const form_data = new FormData();
    for (const key in updateData) {
      form_data.append(key, updateData[key]);
    }

    return this.apiClient
      .post(`/auth/reset-password`, form_data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(({ data }) => {
        return data;
      }); 
  }

  register(
    email,
    password,
    fname,
    lname,
    title,
    organization,
    is_marketer,
    is_consent,
    marketer_questions
  ) {
    const signupData = {
      email,
      password,
      first_name: fname,
      last_name: lname,
      title,
      organization,
      is_marketer,
      is_consent,
      is_active: false,
    };

    const marketerQuestions = { ...marketer_questions };
    return this.apiClient
      .post("/auth/signup", signupData, {
        params: { marketer_questions: marketerQuestions },
      })
      .then((resp) => {
        return [true, resp];
      })
      .catch((error) => {
        if (error.response){
          if (error.response.status === 400) {
            return [false, error.response.data.detail];
          }
        }
  
        // Handle other errors or re-throw if needed
        return [false, "Something went wrong. Please try again!"];
      });
  }

  // logout() {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("user");
  // }

  /* ----- Client Configuration ----- */

  /* Create Axios client instance pointing at the REST api backend */
  getApiClient(config) {
    const initialConfig = {
      baseURL: `${config.apiBasePath}/api/v1`,
    };
    const client = axios.create(initialConfig);
    client.interceptors.request.use(localStorageTokenInterceptor);
    return client;
  }

  wakeUp() {
    return this.apiClient.get(`/auth/start`);
  }

  getChatGroup(mode, id=0) {
    return this.apiClient.get(`/my-chat-groups/${mode}/${id}`).then(({ data }) => {
      return data;
    });
  }

  getChatMessages(group) {
    return this.apiClient.get(`/my-chat-messages/${group}`).then(({ data }) => {
      return data;
    })
  }

  deleteChatGroup(chatGroupId) {
    return this.apiClient.get(`/create/delete_chat_window/${chatGroupId}`).then(({ data }) => {
      return data;
    }).catch(() => {
      return 'error';
    });
  }
  
  renameChatGroup(chatGroupId, title) {
    const chatGroupData = {
      chatGroupId: chatGroupId,
      description: title,
    };

    return this.apiClient.post(`/create/rename_chat_window/`, chatGroupData).then(({ data }) => {
      return data;
    }).catch(() => {
      return 'error';
    });
  }

  updateChatGroupConnector(chatGroupId, selectedChatConnector, selectedGoogleLoginCustomerID, selectedGoogleCustomerID) {
    const chatGroupData = {
      chatGroupId: chatGroupId,
      connector: selectedChatConnector,
      google_customer_id: selectedGoogleLoginCustomerID,
      google_login_customer_id: selectedGoogleCustomerID
    };

    return this.apiClient.post(`/create/update_chat_connector/`, chatGroupData).then(({ data }) => {
      return data;
    }).catch(() => {
      return 'error';
    });
  }

  createNewChat(connector='') {
    return this.apiClient.get(`/create/new_chat_window/${connector}`).then(({ data }) => {
      return data;
    });
  }

  askQuestion(message, chat_group, is_insight, chatConnector='') {
    const questionData = {
      message: message,
      chat_group_id: chat_group,
      // owner_id: user_id,
      is_insight: is_insight,
      chatConnector: chatConnector,
      is_from_user: true,
    };
    return this.apiClient.post(`/ask/`, questionData)
      .then(({ data }) => {
        return data;
      }).catch((error) => {
        if (error.response && error.response.status === 402) {
          console.log(error.response)
          if (error.response.data.detail === 'Not subscribed') {
            return 'User not subscribed.';
          } else {
            return 'Access denied: ' + error.response.data.message;
          }
        }
        return 'error';
      });
    }

  inviteMarketer(email) {
    return this.apiClient
      .post(`/invitation-request/?email=${email}`)
      .then((resp) => {
        return resp.data;
      });
  }

  uploadFile(file) {
    return this.apiClient.post(`/insights/upload`, file).then(({ data }) => {
      return data;
    });
  }

  loginGoogle(codeResponse) {
    return this.apiClient.post(`/google/auth`, {}, {params: codeResponse}).then((tokens) => {
      return tokens.data;
    })
  }

  getGoogleLoginCustomerIds(){
    return this.apiClient.get(`/google/get_login_customer_ids`).then((response) => {
      // console.log(response.data)
      return response.data;
    })
  }

  getGoogleCustomerIds(googleLoginCustomerId){
    return this.apiClient.get(`/google/get_customer_ids/${googleLoginCustomerId}`).then((response) => {
      // console.log(response.data)
      return response.data;
    })
  }

  getGoogleCampaignNames(googleLoginCustomerId, loginCustomerId){
    return this.apiClient.get(`/google/get_account_campaigns/${googleLoginCustomerId}/${loginCustomerId}`).then((response) => {
      return response.data;
    })
  }

  // stripe API
  makeStripePayment() {
    return this.apiClient.post(`/stripe/manage-checkout-billing-session`)
      .then(response => {
        window.location.href = response.data.redirectUrl;
      })
      .catch((error) => {
        console.error('Payment initiation failed:', error);
      });
  }
}

// every request is intercepted and has auth header injected.
function localStorageTokenInterceptor(config) {
  const headers = {'Cache-Control': 'no-cache, no-store'};
  const tokenString = localStorage.getItem("authToken");

  if (tokenString) {
    const token = JSON.parse(tokenString);
    const decodedAccessToken = jwtDecode(token.access_token);
    const isAccessTokenValid =
      moment.unix(decodedAccessToken.exp).toDate() > new Date();
    if (isAccessTokenValid) {
      headers["Authorization"] = `Bearer ${token.access_token}`;
    }
    //  else {
    //   alert("Your login session has expired");
    // }
  }
  config["headers"] = headers;
  return config;
}

export default FastAPIClient;
