import React, { useState, useContext } from "react";
// import { Link as TargetLink } from 'react-scroll'
import { Link } from "react-router-dom";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ModalContext } from "../../contexts/ModalContext";
import LoginModal from "../LoginModal";
import SignupModal from "../Modal/SignupModal";
import Logo from "../../assets/images/ahoora_metflow.png";

import { Button } from "@chakra-ui/react";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const { openModal } = useContext(ModalContext);

  const handleClick = () => setNav(!nav);
  // const handleClose = () => setNav(!nav)

  return (
    <div className="fixed z-10 h-[80px] w-screen bg-zinc-200 drop-shadow-lg">
      <div className="flex h-full w-full items-center justify-between px-2">
        <div className="flex items-center">
          <Link to="/">
            <img src={Logo} className="h-14" />
          </Link>
        </div>
        <div className="hidden pr-4 md:flex">
          <button
            className="mr-4 border-none bg-transparent text-black"
            onClick={() => openModal(<LoginModal />)}
          >
            Log In
          </button>
          {/* <Button className='px-8 py-3' onClick={() => openModal(<SignupModal />)}>Sign Up</Button> */}
          <Button onClick={() => openModal(<SignupModal />)}>Sign Up</Button>
        </div>
        <div className="mr-4 md:hidden" onClick={handleClick}>
          {!nav ? <MenuIcon className="w-6" /> : <XIcon className="w-6" />}
        </div>
      </div>

      <ul className={!nav ? "hidden" : "absolute w-full bg-zinc-200 px-8"}>
        <div className="my-4 flex flex-col">
          <Link to="/login">
            <button className="mb-4 w-full bg-transparent px-8 py-3 text-indigo-600">
              Log In
            </button>
          </Link>
          <Link to="/signup">
            <button className="w-full px-8 py-3">Sign Up</button>
          </Link>
        </div>
      </ul>
    </div>
  );
};

export default Navbar;
